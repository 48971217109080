import React, { useContext, useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import ReactTooltip from 'react-tooltip';
import cn from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { toggleAgreeModalLoginIn } from '../../_actions';
import AgeModal from '../../_modals/AgeModal';
import LoginModalContext from '../../_contexts/loginModalContext';
import TranslationContainer from '../TranslationContainer';
import heartSvgFill32 from '../../assets/images/heart-32-fill.svg';
import favoriteSvg from '../../assets/images/heart-32.svg';
import st from './FavoriteButton.module.scss';
import { couponStatus } from '../../_selectors/coupon.selector';

const cx = cn.bind(st);

export default function FavoriteButton({
  rtl,
  isLogged,
  user,
  language,
  coupon,
  favouriteCoupons,
  inSlider,
  handleAddToFavorite,
  handleRemoveFromFavourite,
  variant,
}) {
  const tooltipContainer = document.getElementById('tooltip-container');
  const dispatch = useDispatch();
  const context = useContext(LoginModalContext);

  const isCouponLoading = useSelector(couponStatus);

  const [isFavorite, setIsFavorite] = useState(coupon && favouriteCoupons?.some((item) => item?._id === coupon?._id));
  const requestTracker = useRef(new Set());

  const handleToggle = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (isCouponLoading || requestTracker.current.has(coupon?._id)) return;

    requestTracker.current.add(coupon?._id);

    try {
      if (!isLogged) {
        context.openLogin();
        return;
      }

      const performAction = async () => {
        if (!isFavorite) {
          if (user.agree1) {
            await handleAddToFavorite(e, coupon);
          } else {
            dispatch(toggleAgreeModalLoginIn(false, true));
            return;
          }
        } else {
          await handleRemoveFromFavourite(e, coupon._id);
        }
        setIsFavorite((prev) => !prev);
      };

      if (!isFavorite && coupon.alcoholAndTobaco?.[0]?.value !== 0) {
        AgeModal.confirm({ coupon, language, onOk: performAction });
      } else {
        await performAction();
      }
    } finally {
      requestTracker.current.delete(coupon?._id);
    }
  };

  const renderTooltip = (id, translationKey) => tooltipContainer
    && ReactDOM.createPortal(
      <ReactTooltip
        id={id}
        place={rtl ? 'left' : 'right'}
        type="dark"
        effect="solid"
        className="tooltip-element-custom"
      >
        <p>
          <TranslationContainer translationKey={translationKey} />
        </p>
      </ReactTooltip>,
      tooltipContainer,
    );

  return (
    <div
      className={cx('heart', { rtl, [variant]: variant, loading: isCouponLoading })}
      onClick={handleToggle}
      data-tip={!isCouponLoading}
      data-for={!isCouponLoading && !inSlider && `tooltip-id${isFavorite ? '_del' : ''}${coupon?._id}`}
    >
      <img src={isFavorite ? heartSvgFill32 : favoriteSvg} alt="heart" />
      {inSlider ? (
        <div className={st.sliderTooltip}>
          <p>
            <TranslationContainer translationKey={isFavorite ? 'remove_from_saved_coupons' : 'add_to_saved_coupons'} />
          </p>
        </div>
      ) : (
        !isCouponLoading
        && renderTooltip(
          `tooltip-id${isFavorite ? '_del' : ''}${coupon?._id}`,
          isFavorite ? 'remove_from_saved_coupons' : 'add_to_saved_coupons',
        )
      )}
    </div>
  );
}
