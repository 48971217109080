import { couponConstants, TRANSLATIONS } from '../_constants';
import { couponService } from '../_services';
import { toast } from '../_helpers/toast';

import 'regenerator-runtime/runtime';

export function getCoupon(id) {
  return (dispatch) => {
    dispatch({ type: couponConstants.GET_REQUEST_COUPON });
    couponService.getCoupon(id).then((data) => {
      dispatch({ type: couponConstants.GET_SUCCESS_COUPON, data });
    });
  };
}

export function clearCoupon(id) {
  return (dispatch) => dispatch({ type: couponConstants.CLEAR_COUPON });
}

export function clearCoupons() {
  return (dispatch) => dispatch({ type: couponConstants.CLEAR_COUPONS });
}

export function getCouponsFooter(id) {
  return (dispatch) => {
    couponService
      .getCouponsFooter(id)
      .then((data) => {
        dispatch({ type: couponConstants.GET_SUCCESS_COUPON_FOOTER, data });
      })
      .catch((error) => { });
  };
}
export function getFavoriteCoupons() {
  return (dispatch) => {
    couponService.getFavoriteCoupons().then(
      (data) => {
        dispatch({ type: couponConstants.GET_SUCCESS_COUPONS_FAVOURITE, data });
      },
      (error) => {
        dispatch({ type: couponConstants.GET_FAILURE_COUPONS_FAVOURITE, error: error.toString() });
      },
    );
  };
}

export function getCoupons(d, filterID) {
  const currentFilterID = filterID || 3;

  const data = {
    ...(filterID !== 0 && { filterID: currentFilterID }),
    ...d,
    page: d.page || 1,
    user: d.user,
    fe_page: 'nearby',
  };

  return (dispatch) => {
    dispatch({ type: couponConstants.REQUEST_COUPONS_REQUEST });

    couponService.getCoupons(data).then(
      (data) => {
        dispatch({ type: couponConstants.SUCCESS_COUPONS_REQUEST, data });
      },
      (error) => {
        dispatch({ type: couponConstants.FAILURE_COUPONS_REQUEST, error: error.toString() });
      }
    );
  };
}

export function searchCoupons(d) {
  const data = {
    ...d,
    filterID: JSON.parse(sessionStorage.getItem('filterID') || '3'),
    page: d.page || 1,
  };
  return (dispatch) => {
    dispatch({ type: couponConstants.REQUEST_COUPONS_REQUEST });

    couponService.searchCoupons(data).then(
      (data) => {
        dispatch({ type: couponConstants.SUCCESS_COUPONS_REQUEST, data });
      },
      (error) => {
        dispatch({ type: couponConstants.FAILURE_COUPONS_REQUEST, error: error.toString() });
      }
    );
  };
}

export function getExtraCoupons(data) {
  return (dispatch) => {
    couponService.getExtraCoupons(data).then((data) => {
      dispatch({ type: couponConstants.SUCCESS_EXTRACOUPONS_REQUEST, data });
    });
  };
}

export function addToFavourite(coupon, language) {
  return (dispatch) => {
    dispatch({ type: couponConstants.ADD_REQUEST_COUPON_FAVOURITE });
    couponService.addToFavourite(coupon._id).then(
      () => {
        dispatch({ type: couponConstants.ADD_SUCCESS_COUPON_FAVOURITE, coupon });
        toast({
          type: 'success',
          text: TRANSLATIONS[language].has_been_added,
        });
      },
      (error) => {
        dispatch({ type: couponConstants.ADD_FAILURE_COUPON_FAVOURITE, error: error.toString() });
      }
    );
  };
}

export function removeFromFavourite(id, language) {
  return (dispatch) => {
    dispatch({ type: couponConstants.REMOVE_REQUEST_COUPON_FAVOURITE });
    couponService.removeFromFavourite(id).then(
      () => {
        dispatch({ type: couponConstants.REMOVE_SUCCESS_COUPON_FAVOURITE, id });
        toast({
          type: 'info',
          text: TRANSLATIONS[language].has_been_removed,
        });
      },
      (error) => {
        dispatch({ type: couponConstants.REMOVE_FAILURE_COUPON_FAVOURITE, error: error.toString() });
      }
    );
  };
}

export function removeAllCoupons(ids) {
  return async (dispatch) => {
    dispatch({ type: couponConstants.REMOVE_ALL_FAVOURITE_COUPONS_REQUEST });

    const coupons = [];

    ids.map((coupon) => coupons.push(coupon._id));

    await couponService.removeAllCoupons(coupons).then(
      () => {
        dispatch({ type: couponConstants.REMOVE_ALL_FAVOURITE_COUPONS_SUCCESS });
      },
      (error) => {
        dispatch({ type: couponConstants.REMOVE_ALL_FAVOURITE_COUPONS_FAILURE, error: error.toString() });
      }
    );
  };
}

export function onPreviousCouponPage(data) {
  return (dispatch) => {
    dispatch({ type: couponConstants.ON_PREVIOUS_COUPON_PAGE, data });
  };
}
