export const couponConstants = {
  GET_SUCCESS_COUPON: 'GET_SUCCESS_COUPON',
  GET_REQUEST_COUPON: 'GET_REQUEST_COUPON',
  CLEAR_COUPON: 'CLEAR_COUPON',
  CLEAR_COUPONS: 'CLEAR_COUPONS',
  GET_SUCCESS_COUPON_FOOTER: 'GET_SUCCESS_COUPON_FOOTER',
  GET_SUCCESS_COUPONS_FAVOURITE: 'GET_SUCCESS_COUPONS_FAVOURITE',
  GET_FAILURE_COUPONS_FAVOURITE: 'GET_FAILURE_COUPONS_FAVOURITE',

  ADD_REQUEST_COUPON_FAVOURITE: 'ADD_REQUEST_COUPON_FAVOURITE', // Newly added
  ADD_SUCCESS_COUPON_FAVOURITE: 'ADD_SUCCESS_COUPON_FAVOURITE',
  ADD_FAILURE_COUPON_FAVOURITE: 'ADD_FAILURE_COUPON_FAVOURITE',

  REMOVE_REQUEST_COUPON_FAVOURITE: 'REMOVE_REQUEST_COUPON_FAVOURITE', // Newly added
  REMOVE_SUCCESS_COUPON_FAVOURITE: 'REMOVE_SUCCESS_COUPON_FAVOURITE',
  REMOVE_FAILURE_COUPON_FAVOURITE: 'REMOVE_FAILURE_COUPON_FAVOURITE',

  REMOVE_ALL_FAVOURITE_COUPONS_REQUEST: 'REMOVE_ALL_FAVOURITE_COUPONS_REQUEST',
  REMOVE_ALL_FAVOURITE_COUPONS_SUCCESS: 'REMOVE_ALL_FAVOURITE_COUPONS_SUCCESS',
  REMOVE_ALL_FAVOURITE_COUPONS_FAILURE: 'REMOVE_ALL_FAVOURITE_COUPONS_FAILURE',

  REQUEST_COUPONS_REQUEST: 'REQUEST_COUPONS_REQUEST',
  FAILURE_COUPONS_REQUEST: 'FAILURE_COUPONS_REQUEST',

  SUCCESS_COUPONS_REQUEST: 'SUCCESS_COUPONS_REQUEST',

  SUCCESS_EXTRACOUPONS_REQUEST: 'SUCCESS_EXTRACOUPONS_REQUEST',

  ON_PREVIOUS_COUPON_PAGE: 'ON_PREVIOUS_COUPON_PAGE',
};
